<template>
  <v-container>
    <v-row>
      <!-- WELCOME TEXT -->
      <v-col sm="12">
        <h1 class="text-h4 primary--text">
          About Our Agency
        </h1>
        <h2 class="text-subtitle-1 primary--text"
          >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minus,
          eveniet iusto assumenda quidem ducimus neque?</h2
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  page: {
    title: '',
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
